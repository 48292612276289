import {
  CategoriesTreeStateType,
  ICategoryTreeItem,
  SequenceItemType,
} from "@/types"
import { cookies, getExpiresByHour } from "@/utils/common/helpers"
import { Category, CategoryByArea } from "../../../contracts"

export const LAST_CATEGORY_KEY = "lastCategory"
export const LAST_CATEGORY_PARENT_KEY = "lastParentCategory"

type CreateCategoriesTreeFnType = (
  categoriesInput: Category[],
) => CategoriesTreeStateType

export const isParentCategory = ({ parent }: { parent: number[] }) => {
  return parent.length === 0
}

export const createCategoriesTree: CreateCategoriesTreeFnType = (
  categoriesInput,
) => {
  if (!categoriesInput) {
    return {
      treeCompared: null,
      treeSorted: [],
    }
  }

  const categories = [...categoriesInput]

  const treeCompared: Record<string, Record<number, SequenceItemType[]>> = {}

  const categoriesTree: Record<string, ICategoryTreeItem> = {}

  const categoriesFetched: Record<number, ICategoryTreeItem> =
    categories.reduce((o, c) => {
      const category = { ...c, children: {} }

      if (isParentCategory({ parent: c.parent || [] }) && !!category.uuid) {
        categoriesTree[category.uuid] = category
      }

      treeCompared[c.uuid || ""] = {}

      return (
        !!c.id
          ? {
              ...o,
              [c.id]: category,
            }
          : o
      ) as Record<number, ICategoryTreeItem>
    }, {}) as Record<number, ICategoryTreeItem>

  const _categories: Category[] | null = categories
    .filter((c) => !isParentCategory({ parent: c.parent || [] }))
    .sort((a, b) => {
      const pA = a?.id || 0
      const pB = b?.id || 0
      return pA > pB ? 1 : pA < pB ? -1 : 0
    })

  const appendToTreeCompared = (
    sliceParents: number[],
    sequence: SequenceItemType[],
  ) => {
    if (
      isParentCategory({
        parent: sliceParents,
      })
    ) {
      return
    }

    for (const parentIdSlice of sliceParents) {
      const p = categoriesFetched[`${parentIdSlice}`]
      if (!p) {
        continue
      }

      const { uuid, id } = p
      sequence.unshift({
        uuid: uuid,
        id: id,
      })

      appendToTreeCompared(p.parent || [], sequence)
    }

    return
  }

  const treeComparedCreate = (category: Category) => {
    const categoryParent: number[] = category.parent || []
    const uuidCategory = category.uuid

    if (!uuidCategory || categoryParent.length === 0) {
      return
    }

    categoryParent.forEach((parentId) => {
      const sliceParent = categoriesFetched[`${parentId}`]

      if (!sliceParent) {
        return
      }

      treeCompared[uuidCategory][`${parentId}`] = [
        {
          uuid: sliceParent.uuid,
          id: sliceParent.id,
        },
        ...(treeCompared[uuidCategory][`${parentId}`] || []),
      ]

      appendToTreeCompared(
        sliceParent.parent ?? [],
        treeCompared[uuidCategory][`${parentId}`],
      )
    })

    return
  }

  for (const cat of _categories) {
    treeComparedCreate(cat)
  }

  const treeCategoriesCreate = (
    sequenceParents: Record<number, SequenceItemType[]>,
    category: ICategoryTreeItem,
  ) => {
    Object.values(sequenceParents).forEach((sqArr) => {
      const sq: SequenceItemType[] = [
        ...sqArr,
        {
          uuid: category.uuid,
          id: category.id,
        },
      ]

      let tree = categoriesTree

      sq.forEach((sqItem) => {
        const uuid = sqItem.uuid || ""
        const id = sqItem.id || ""
        if (!tree.hasOwnProperty(uuid)) {
          tree[uuid] = {
            ...categoriesFetched[`${id}`],
          }
        }

        tree = tree[uuid].children
      })
    })
  }

  for (const cat of _categories) {
    const category = {
      ...cat,
      children: {},
    } as ICategoryTreeItem

    if (
      !category.uuid ||
      category.id === undefined ||
      category.parent === undefined
    ) {
      continue
    }

    const sliceCompared = treeCompared[category.uuid]
    if (!sliceCompared) {
      continue
    }
    // console.log("sliceCompared ", sliceCompared)
    // console.log("category.uuid ", category.uuid)

    treeCategoriesCreate(sliceCompared, category)
  }

  const treeSorted = Object.entries(categoriesTree)
    .filter(([, c]) =>
      isParentCategory({
        parent: c.parent || [],
      }),
    )
    .sort(([, catA], [, catB]) => {
      const nameA = catA.name || ""
      const nameB = catB.name || ""
      return nameA > nameB ? 1 : nameA < nameB ? -1 : 0
    })
    .sort(([, catA], [, catB]) => {
      const weightA = catA.weight || ""
      const weightB = catB.weight || ""
      return weightA > weightB ? 1 : weightA < weightB ? -1 : 0
    })
    .map(([, category]) => category)

  // categoriesTree = null
  // compareChildMain = null
  // _categories = null

  return {
    treeCompared: treeCompared,
    treeSorted: treeSorted,
  }
}

export const normalizeCategoriesByAreas = (
  areas: CategoryByArea[],
  categories: Record<string, Category>,
): ICategoryTreeItem[] => {
  const fetchedBusinessAreas = [] as ICategoryTreeItem[]

  const createChilds = ({
    childsAreas,
  }: {
    childsAreas?: CategoryByArea[]
  }): Record<string, ICategoryTreeItem> => {
    if (!!childsAreas && childsAreas.length > 0) {
      return childsAreas.reduce(
        (obj: Record<string, ICategoryTreeItem>, curr) => {
          const currCategory = categories[curr.uuid || ""]
          return !currCategory
            ? obj
            : {
                ...obj,
                [curr.uuid || ""]: {
                  ...currCategory,
                  product_qty: curr.product_qty,
                  weight: curr.weight,
                  children: createChilds({
                    childsAreas: curr.children,
                  }),
                },
              }
        },
        {},
      )
    } else {
      return {}
    }
  }

  for (const mainCategory of areas) {
    const currCategory = categories[mainCategory.uuid || ""]
    if (!currCategory) {
      continue
    }

    fetchedBusinessAreas.push({
      ...currCategory,
      product_qty: mainCategory.product_qty,
      weight: mainCategory.weight,
      children: createChilds({
        childsAreas: mainCategory.children,
      }),
    })
  }

  return fetchedBusinessAreas
}
export const setLastCategoryStorage = (uuid: string | null) => {
  if (typeof sessionStorage === "undefined" || !sessionStorage) {
    return undefined
  }

  if (uuid !== null) {
    sessionStorage.setItem(LAST_CATEGORY_KEY, JSON.stringify(uuid))
  } else {
    sessionStorage.removeItem(LAST_CATEGORY_KEY)
  }
}
export const getLastCategoryStorage = (): string | null => {
  const uuid = sessionStorage.getItem(LAST_CATEGORY_KEY)
  return !!uuid ? (JSON.parse(uuid) as string) : null
}
export const getLastCategoryParentStorage = (): number | null => {
  const identifier = cookies.get(LAST_CATEGORY_PARENT_KEY) as string | undefined
  return !!identifier ? Number(identifier) : null
}
export const setLastCategoryParentStorage = (id: number | null) => {
  if (id !== null) {
    cookies.set(LAST_CATEGORY_PARENT_KEY, JSON.stringify(id), {
      path: "/",
      expires: getExpiresByHour(0.1),
    })
  } else {
    cookies.remove(LAST_CATEGORY_PARENT_KEY, { path: "/" })
  }
}

export const cleanCategoriesStorage = () => {
  window.onbeforeunload = function () {
    setLastCategoryParentStorage(null)
    setLastCategoryStorage(null)
  }
}

export const REDIRECTS_CATEGORIES_IGRUSHKI = [
  "/catalog/igrushki/7384",
  "/catalog/igrushki/7446",
  "/catalog/igrushki/8493",
  "/catalog/igrushki/7385",
  "/catalog/igrushki/9344",
  "/catalog/igrushki/10902",
  "/catalog/igrushki/7506",
  "/catalog/igrushki/7364",
  "/catalog/igrushki/12534",
  "/catalog/igrushki/6943",
  "/catalog/igrushki/8494",
  "/catalog/igrushki/11522",
  "/catalog/igrushki/10840",
  "/catalog/igrushki/7383",
  "/catalog/igrushki/8585",
  "/catalog/igrushki/7077",
  "/catalog/igrushki/6944",
  "/catalog/igrushki/10818",
  "/catalog/igrushki/9027",
  "/catalog/igrushki/7082",
  "/catalog/igrushki/10921",
  "/catalog/igrushki/11524",
  "/catalog/igrushki/9331",
  "/catalog/igrushki/8032",
  "/catalog/igrushki/12606",
  "/catalog/igrushki/10836",
  "/catalog/igrushki/8097",
  "/catalog/igrushki/7495",
  "/catalog/igrushki/12535",
  "/catalog/igrushki/9028",
  "/catalog/igrushki/10838",
  "/catalog/igrushki/11521",
  "/catalog/igrushki/10839",
  "/catalog/igrushki/10903",
  "/catalog/igrushki/11289",
  "/catalog/igrushki/11046",
  "/catalog/igrushki/11045",
  "/catalog/igrushki/10824",
  "/catalog/igrushki/10831",
  "/catalog/igrushki/11572",
  "/catalog/igrushki/10834",
  "/catalog/igrushki/10832",
  "/catalog/igrushki/7447",
  "/catalog/igrushki/7382",
  "/catalog/igrushki/7811",
]

export const DESTINATION_REDIRECTS_CATEGORIES_IGRUSHKI = "/catalog/igrushki"
