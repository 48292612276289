import { cx } from "@linaria/core";
import { Portal } from "reakit";
import { ComponentType, FC, useCallback, useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { BannerHeader } from "@/components/Banners/Header";
import { SearchField } from "@/components/Search/Field";
import { StyledSearchPopup } from "@/components/Search/StyledSearch";
import { LazyHydrateContainer } from "@/hoc/LazyHydrate";
import { useApp } from "@/hooks/app/app";
import { useModals } from "@/hooks/modals/useModals";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useScrollDirection } from "@/hooks/scrollDirection";
import { useWindowSize } from "@/hooks/useWindowSize";
import { ButtonToCompares } from "@/layouts/Default/Header/ToCompares";
import { popupsAlias } from "@/store/reducers/commonSlice";
import { setIsShowFullScreenSearch } from "@/store/reducers/searchSlice";
import { Container } from "@/styles/utils/StyledGrid";
import { cssHiddenLG, cssIsActive, getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { Button } from "@/ui/Button/Button";
import { cssButtonCatalogPopup, cssButtonMenuToggle } from "@/ui/Button/StyledButton";
import { ROUTES } from "@/utils/constants";
import { AuthControls } from "./Auth/AuthControls";
import Logo from "./Logo/Logo";
import { NavigationStatic } from "./NavigationStatic";
import { ResponsiveMenuPropsType } from "./ResponsiveMenu/ResponsiveMenu";
import { LocationPopover, SelectLocation } from "./SelectLocation";
import { useLocationPopover } from "./SelectLocation/LocationPopover";
import { ButtonCatalogContainer, Controls, cssButtonSearchTrigger, cssHeaderCart, cssHeaderDefault, cssHeaderIsFixed, cssHeaderOverlayMode, cssIsShowAutoComplete, cssScrollDown, cssScrollUp, RowBottom, RowTop, RowWrap, StyledHeader } from "./StyledHeader";
import { ButtonToCart } from "./ToCart";
import { ButtonToFavorite } from "./ToFavorite";
import { Provider as HeaderProvider, useHeader } from "../../../hooks/header/header";
import { Provider as SearchProvider } from "../../../hooks/search/search";
const CallInfo = dynamic(() => import("./CallInfo").then(mod => mod.CallInfo));
const ResponsiveMenu: ComponentType<ResponsiveMenuPropsType> = dynamic(() => import("./ResponsiveMenu/ResponsiveMenu").then(mod => mod.ResponsiveMenu));
const CatalogPopup = dynamic(() => import("@/components/Catalog/Popup").then(mod => mod.Popup));
const Notification = dynamic(() => import("@/components/Notifications/NotificationModal/NotificationModal").then(mod => mod.NotificationModal));
const Header: FC = () => {
  const {
    categories,
    businessAreas
  } = useAppSelector(({
    categories
  }) => categories);
  const {
    popup: {
      isShow: catalogPopupIsShow
    },
    categoriesByAreas
  } = useAppSelector(({
    catalog
  }) => catalog);
  const {
    autoComplete: {
      isShow: isShowAutoComplete
    },
    fullScreen: {
      isShow: isShowFullScreenSearch
    }
  } = useAppSelector(({
    search
  }) => search);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const headerRef = useRef<HTMLDivElement>(null);
  const {
    layouts: {
      header: {
        isOverlay,
        isFixed,
        enabledOverlay,
        setIsOverlay,
        setRect,
        rect
      }
    }
  } = useApp();
  const {
    isShowMiniCart,
    isShowMenu,
    notificationAuth,
    setNotificationAuth,
    catalogShowedToggle,
    menuHide,
    menuShow
  } = useHeader();
  const scroll = useScrollDirection();
  const {
    width
  } = useWindowSize();
  const isLessLg = width !== undefined && width <= getBreakpointVal(breakpoints.lg);
  const isMoreLg = width !== undefined && width > getBreakpointVal(breakpoints.lg);
  const locationPopoverProps = useLocationPopover();
  const {
    hide,
    show
  } = useModals();
  const pageIsCart = router.pathname.includes(ROUTES.cart);
  const offsetLayer = (rect?.clientHeight ?? 0) + (rect?.offsetTop ?? 0);
  const setIsShowMiniCartHandle = useCallback((value: boolean) => {
    if (!catalogPopupIsShow && isMoreLg) {
      const method = value ? show : hide;
      method({
        alias: popupsAlias.miniCartHeader
      });
    }
  }, [catalogPopupIsShow, isMoreLg, hide, show]);
  const fullScreenSearchShow = () => {
    isShowMenu && menuHide();
    dispatch(setIsShowFullScreenSearch(true));
  };
  useEffect(() => {
    switch (scroll) {
      case "up":
        {
          document.body.classList.add(cssScrollUp);
          document.body.classList.remove(cssScrollDown);
          break;
        }
      case "down":
        {
          document.body.classList.remove(cssScrollUp);
          document.body.classList.add(cssScrollDown);
          break;
        }
      case null:
        {
          document.body.classList.remove(cssScrollUp);
          document.body.classList.remove(cssScrollDown);
        }
    }
  }, [scroll]);
  const handleMenuShow = useCallback(() => {
    menuShow();
    locationPopoverProps.hidePopover();
    if (catalogPopupIsShow) {
      catalogShowedToggle(false);
    }
  }, [catalogPopupIsShow, menuShow, catalogShowedToggle, locationPopoverProps]);
  const onMouseEnterHandle = () => {
    if (!enabledOverlay) {
      return;
    }
    setIsOverlay(false);
  };
  const onMouseLeaveHandle = () => {
    if (!enabledOverlay) {
      return;
    }
    if (isShowMenu || catalogPopupIsShow || isShowAutoComplete || scroll !== null) {
      return;
    }
    setIsOverlay(true);
  };
  useEffect(() => {
    // при открытии моб. меню также отключаем прозрачность хедера
    if (isShowMenu || catalogPopupIsShow) {
      setIsOverlay(false);
    }
  }, [isShowMenu, catalogPopupIsShow, setIsOverlay]);

  // сохраняем размер header на странице
  // используется для отступов в баннере каталога, тк там header - fixed
  useEffect(() => {
    const headerElement = headerRef.current;
    if (!headerElement) {
      setRect(null);
    } else {
      const offsetTop = headerElement.offsetTop - window.scrollY;
      setRect({
        offsetTop: offsetTop < 0 ? 0 : offsetTop,
        clientHeight: headerElement.clientHeight
      });
    }
  }, [setRect, width, scroll]);
  return <>
      <BannerHeader text={"Перейти на старую версию сайта"} href={"https://old.groster.me/"} />

      <StyledHeader data-isshow-catalog={catalogPopupIsShow} data-issshow-menu={isShowMenu} data-issshow-minicart={isShowMiniCart} className={cx(isShowAutoComplete && cssIsShowAutoComplete, cssHeaderDefault, pageIsCart && cssHeaderCart, enabledOverlay && isOverlay && cssHeaderOverlayMode, isFixed && cssHeaderIsFixed)} onMouseEnter={onMouseEnterHandle} onMouseLeave={onMouseLeaveHandle} ref={headerRef}>
        <Container>
          <RowTop>
            <RowWrap>
              <SelectLocation />
              <NavigationStatic />
            </RowWrap>
            <AuthControls />
          </RowTop>

          <RowBottom>
            {isLessLg && <>
                <Button variant={"box"} icon={isShowMenu ? "X" : "Menu"} className={cssButtonMenuToggle} onClick={isShowMenu ? menuHide : handleMenuShow} />
                <LocationPopover {...locationPopoverProps} />
              </>}

            <Logo isOverlay={enabledOverlay && isOverlay} />

            <ButtonCatalogContainer>
              <Button as={"a"} href={ROUTES.catalog} variant={"filled"} icon={catalogPopupIsShow ? "X" : "ViewBoxes"} onClick={e => {
              e.preventDefault();
              catalogShowedToggle();
            }} className={cx(cssButtonCatalogPopup, catalogPopupIsShow && cssIsActive)}>
                Каталог
              </Button>
            </ButtonCatalogContainer>

            <SearchField className={cssHiddenLG} />

            <Controls>
              <CallInfo />

              <ButtonToCompares />

              <ButtonToFavorite />

              {isLessLg && <Button variant={"box"} icon={"Search"} type={"submit"} className={cx(cssButtonSearchTrigger)} onClick={fullScreenSearchShow} seoText={"Поиск"} />}

              {isMoreLg && <ButtonToCart isShowMiniCart={isShowMiniCart} setIsShowMiniCart={setIsShowMiniCartHandle} />}
            </Controls>

            {isLessLg && <LazyHydrateContainer>
                <ResponsiveMenu isShow={isShowMenu} offset={offsetLayer} />
              </LazyHydrateContainer>}
          </RowBottom>
        </Container>

        <LazyHydrateContainer>
          <CatalogPopup categoriesByAreas={categoriesByAreas} isShow={catalogPopupIsShow} categories={categories} businessAreas={businessAreas} offsetLayer={offsetLayer} />
        </LazyHydrateContainer>

        {notificationAuth !== null && <LazyHydrateContainer whenIdle>
            <Notification isOpen={true} notification={notificationAuth} onClose={() => {
          setNotificationAuth(null);
        }} />
          </LazyHydrateContainer>}

        {isShowFullScreenSearch && <LazyHydrateContainer whenIdle>
            <Portal>
              <StyledSearchPopup>
                <SearchField isResponsiveMode isFocusInit />
              </StyledSearchPopup>
            </Portal>
          </LazyHydrateContainer>}
      </StyledHeader>
    </>;
};
Header.displayName = "Header";
const HeaderWithProviders: FC = () => {
  return <SearchProvider>
      <HeaderProvider>
        <Header />
      </HeaderProvider>
    </SearchProvider>;
};
export { HeaderWithProviders as Header };