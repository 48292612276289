import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export const enum modalsAlias {
  becomePartner = "becomePartner", // used on page /take_lm2 /partner
  registerOnSale = "registerOnSale", // used on page /closed_sale
  takeNote = "takeNote", // used on page /lm1 /lm2
  implementAuth = "implementAuth",
  auth = "auth",
  shared = "shared",
  pwa = "pwa",
  filter = "filter",
}

export const enum popupsAlias {
  miniCartHeader = "miniCartHeader",
  menuHeader = "menuHeader",
}

export type ComputedModalsAlias = modalsAlias | popupsAlias

const initialStateModal = {
  isShow: false,
}

const initialState = {
  modals: {
    [modalsAlias.becomePartner]: initialStateModal,
    [modalsAlias.registerOnSale]: initialStateModal,
    [modalsAlias.takeNote]: initialStateModal,
    [modalsAlias.implementAuth]: initialStateModal,
    [modalsAlias.auth]: initialStateModal,
    [modalsAlias.shared]: initialStateModal,
    [modalsAlias.pwa]: initialStateModal,
    [modalsAlias.filter]: initialStateModal,
    [popupsAlias.menuHeader]: initialStateModal,
    [popupsAlias.miniCartHeader]: initialStateModal,
  },
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setIsShowModal: (
      state,
      {
        payload,
      }: PayloadAction<{
        isShow: boolean
        alias: ComputedModalsAlias
      }>,
    ) => {
      state.modals[payload.alias].isShow = payload.isShow
    },
  },
})

export const { setIsShowModal } = commonSlice.actions
