import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import {
  FrontDeliveryMethod,
  ProductCatalogType,
  RequestBanner,
  SettingsFetchedType,
} from "../../../contracts/contracts"
import { RootStateType } from "../store"
import { LocationExtendsType, LocationType, StoreBannersType } from "@/types"
import { getLocationFormat, makeBanners } from "@/utils/common/helpers"

export type SettingsAppType = {
  shippingFastTime?: string
  shippingShift: number
  holidays: string[]
  minManyQuantity: number
  viber: string | null
  whatsApp: string | null
  telegram: string | null
}

const initialState = {
  settings: null as SettingsAppType | null,
  banners: null as StoreBannersType | null,
  lastHistoryEntry: null as string | null,
  isLoadingPage: false as boolean | false,
  location: null as LocationExtendsType | null,
  isDateToOnlyCompany: false as boolean | false,
  hits: {
    currentCategory: null as string | null,
    initial: null as ProductCatalogType[] | null,
    byCategory: {} as Record<string, ProductCatalogType[] | null>,
  },
  news: {
    currentCategory: null as string | null,
    initial: null as ProductCatalogType[] | null,
    byCategory: {} as Record<string, ProductCatalogType[] | null>,
  },
  shippingMethods: null as FrontDeliveryMethod[] | null,
  pwa: {
    isShowPrompt: false,
  },
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<SettingsFetchedType | null>) => {
      if (action.payload !== null) {
        state.settings = {
          shippingFastTime: action.payload.delivery_fast_time,
          shippingShift: action.payload.delivery_shift || 0,
          holidays: action.payload.holidays || [],
          minManyQuantity: action.payload.min_many_quantity || 0,
          telegram: action.payload.telegram || null,
          whatsApp: action.payload.whatsApp || null,
          viber: action.payload.viber || null,
        }
      } else {
        state.settings = action.payload
      }
    },
    setBanners: (state, { payload }: PayloadAction<RequestBanner[] | null>) => {
      state.banners = payload !== null ? makeBanners(payload) : payload
    },
    setLastHistoryEntry(state, action: PayloadAction<string | null>) {
      state.lastHistoryEntry = action.payload
    },
    setIsLoadingPage(state, action: PayloadAction<boolean>) {
      state.isLoadingPage = action.payload
    },
    setLocation(state, { payload }: PayloadAction<LocationType | null>) {
      state.location = payload !== null ? getLocationFormat(payload) : null
    },
    setIsDateToOnlyCompany(state, { payload }: PayloadAction<boolean>) {
      state.isDateToOnlyCompany = payload
    },
    setHits(
      state,
      {
        payload,
      }: PayloadAction<{
        categoryId: string
        products: ProductCatalogType[] | null
      }>,
    ) {
      state.hits.byCategory[payload.categoryId] = payload.products
    },
    setInitialHits(
      state,
      { payload }: PayloadAction<ProductCatalogType[] | null>,
    ) {
      state.hits.initial = payload
    },
    setHitsCurrentCategory(state, { payload }: PayloadAction<string | null>) {
      state.hits.currentCategory = payload
    },
    resetHitsCurrentCategory(state) {
      state.hits.currentCategory = null
    },
    setNews(
      state,
      {
        payload,
      }: PayloadAction<{
        categoryId: string
        products: ProductCatalogType[] | null
      }>,
    ) {
      state.news.byCategory[payload.categoryId] = payload.products
    },
    setInitialNews(
      state,
      { payload }: PayloadAction<ProductCatalogType[] | null>,
    ) {
      state.news.initial = payload
    },
    setNewsCurrentCategory(state, { payload }: PayloadAction<string | null>) {
      state.news.currentCategory = payload
    },
    resetNewsCurrentCategory(state) {
      state.news.currentCategory = null
    },
    setShippingsMethods(
      state,
      { payload }: PayloadAction<FrontDeliveryMethod[] | null>,
    ) {
      state.shippingMethods = payload
    },
    setIsShowPromptPWA(state, { payload }: PayloadAction<boolean>) {
      state.pwa.isShowPrompt = payload
    },
  },
  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      const payload = action.payload.app as RootStateType["app"]

      return {
        ...state,
        hits: payload.hits || state.hits,
        news: payload.news || state.news,
        banners: payload.banners || state.banners,
        location: payload.location || state.location,
      }
    })
  },
})

export const {
  setSettings,
  setBanners,
  setIsLoadingPage,
  setLocation,
  setIsDateToOnlyCompany,
  setHits,
  setInitialHits,
  setHitsCurrentCategory,
  resetHitsCurrentCategory,
  setNews,
  setInitialNews,
  setNewsCurrentCategory,
  resetNewsCurrentCategory,
  setShippingsMethods,
  setIsShowPromptPWA,
} = appSlice.actions
